import axios from 'axios'
import { getToken } from './auth'
import { RequestError, UnauthorizedError, UnexpectedError } from './errors'
import config from './config'

export async function dashboardAuthenticationVerify(email) {
    return request({
        authenticated: true,
        errorMetadata: {
            email
        },
        method: 'post',
        url:  `${config.internalApiBaseUrl}/dashboard/authenticationVerify`,
        data: {}
    })
}

export async function dashboardSummaryInspect(start, end, aggregationTimeZone) {
    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/dashboard/summaryInspect`,
        data: {
            start: start,
            end: end,
            aggregationTimeZone: aggregationTimeZone
        }
    })
}

export async function dashboardInsightsInspect(start, end, aggregationTimeZone) {
    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/dashboard/insightsInspect`,
        data: {
            start: start,
            end: end,
            aggregationTimeZone: aggregationTimeZone
        }
    })
}

export async function dashboardSessionsInspect(start, end) {
    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/dashboard/sessionsInspect`,
        data: {
            start: start,
            end: end
        }
    })
}

export async function dashboardInvoicesInspect(partnerId) {
    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/dashboard/invoicesInspect`,
        data: {
            partnerId: partnerId
        }
    })
}

export async function dashboardPartnerInspect(partnerId) {
    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/dashboard/partnerInspect`,
        data: {
            partnerId: partnerId
        }
    })
}

export async function dashboardPartnerUpdate(data) {
    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/dashboard/partnerUpdate`,
        data: data
    })
}

export async function dashboardGetPrivateLocations(partnerId) {
    return request({
        authenticated: true,
        method: 'post',
        url:  `${config.internalApiBaseUrl}/dashboard/getPrivateLocations`,
        data: {
            partnerId: partnerId
        }
    })
}

async function request(options) {
    if (options.authenticated) {
        if (!options.headers) {
            options.headers = {}
        }
        options.headers.Authorization = `Bearer ${await getToken()}`
    }

    try {
        return (await axios(options)).data
    } catch (err) {
        if (err.response) {
            const resp = err.response

            if (resp.status === 401 || resp.status === 403) {
                throw new UnauthorizedError(err, options.errorMetadata)
            }

            const errorDetail = resp.data && resp.data.errorDetail ? resp.data.errorDetail : null
            throw new RequestError(resp.status, errorDetail, err, options.errorMetadata)
        }

        throw new UnexpectedError(err)
    }
}