import { DatePicker, Tabs } from 'antd'
import moment from 'moment'
import React, { Component, Fragment } from 'react'
import styled from 'styled-components'

const StyledMonthPicker = styled(DatePicker.MonthPicker)`
  margin: 0 24px 24px 24px;
`

const StyledTabsContainer = styled.div`
  & .ant-tabs-bar {
    padding-left: 24px !important;
  }
 
  & .ant-tabs-tab {
    margin-right: 8px !important;
  }
  
  & .ant-tabs-tabpane {
    padding-top: 0.5rem;
  }
`

const StyledContent = styled.div`
  padding: 0 24px 0 24px;
  overflow-y: auto;
  height: calc(100vh - 15rem);
`

export default class Dashboard extends Component {
  static navigateTab(yearMonth, tab) {
    if (tab !== 'breakdown') {
      return `/dashboard/${yearMonth}/${tab}`
    }

    if (moment().format('YYYY-MM') === yearMonth) {
      return `/dashboard/${yearMonth}/${tab}/${moment().format('DD')}`
    }

    return `/dashboard/${yearMonth}/${tab}/01`
  }

  static navigateMonth(date, tab) {
    if (date == null) {
      date = moment()
    }
    const yearMonth =  date.format('YYYY-MM')

    if (tab !== 'breakdown') {
      return `/dashboard/${date.format('YYYY-MM')}/${tab}`
    }

    if (moment().format('YYYY-MM') === yearMonth) {
      return `/dashboard/${yearMonth}/${tab}/${moment().format('DD')}`
    }

    return `/dashboard/${yearMonth}/${tab}/01`
  }

  componentDidUpdate() {
    const { yearMonth, navigate } = this.props
    if (!moment(yearMonth, 'YYYY-MM', true).isValid()) {
      navigate(Dashboard.navigateMonth(moment(), this.props['*'].split('/')[0]))
    }
  }

  render() {
    const { children, navigate, yearMonth } = this.props
    const tab = this.props['*'].split('/')[0]
    const date = moment(yearMonth, 'YYYY-MM', true)

    if (!date.isValid()) {
      return <Fragment/>
    }

    return (
      <Fragment>
        <StyledMonthPicker format='MMMM YYYY' placeholder='Select Month' onChange={date => navigate(Dashboard.navigateMonth(date, tab))} value={date.isValid() ? date : null} />
        {date.isValid() &&
          <StyledTabsContainer>
            <Tabs activeKey={tab} onChange={tab => navigate(Dashboard.navigateTab(yearMonth, tab))} type='card'>
              <Tabs.TabPane key='summary' tab='Summary'>{tab === 'summary' && <StyledContent>{children}</StyledContent>}</Tabs.TabPane>
              <Tabs.TabPane key='insights' tab='Insights'>{tab === 'insights' && <StyledContent>{children}</StyledContent>}</Tabs.TabPane>
              <Tabs.TabPane key='breakdown' tab='Breakdown'>{tab === 'breakdown' && <StyledContent>{children}</StyledContent>}</Tabs.TabPane>
            </Tabs>
          </StyledTabsContainer>
        }
      </Fragment>
    )
  }
}
