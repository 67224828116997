import { ResponsiveBar } from '@nivo/bar'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  background-color: rgb(251, 251, 251);
  border: 1px solid rgb(232, 232, 232);
  border-radius: 4px;
  margin: 24px 0 24px 0;

  & p {
    text-align: center;
    padding-top: 16px;
    padding-bottom: 0;
  }   
  
  & > div {
    height: 20rem;
  }
`

export default class DailySessionsChart extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    yearMonth: PropTypes.string.isRequired
  }

  render() {
    const { data, yearMonth } = this.props

    return (
      <Container>
        <p>Delivered Energy by Day</p>
        <div>
          <ResponsiveBar
            data={data}
            keys={[ 'totalEnergyWattHours' ]}
            indexBy='day'
            colors={[ '#36da96' ]}
            margin={{ top: 10, right: 30, bottom: 65, left: 90 }}
            padding={0.3}
            isInteractive={true}
            animate={false}
            tooltip={v => <span>{yearMonth}-{v.data.day}: ~ {v.value} Wh</span>}
            enableLabel={false}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Day',
              legendPosition: 'middle',
              legendOffset: 40
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Delivered Energy (Wh)',
              legendPosition: 'middle',
              legendOffset: -60
            }}
          />
        </div>
      </Container>
    )
  }
}