import { Alert, Calendar, Col, Descriptions, Row, Table, Typography } from 'antd'
import moment from 'moment'
import 'moment-duration-format'
import React, {Component, Fragment} from 'react'
import { Loads } from 'react-loads'
import styled from 'styled-components'
import Dashboard from '../Dashboard'
import HelpIcon from '../../components/common/HelpIcon'
import LocalLoader from "../../components/common/LocalLoader";
import { dashboardSessionsInspect } from '../../logic/network'
import { formatDate, googleMapsUrl, staticMapUrlSessionDetail}  from '../../logic/utils'

const StyledContainer = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  margin-bottom: 24px;
  width: 100%;
  
  & .ant-fullcalendar-full {
    border-top: none;
  }
  
  & table {
    height: 200px;
  }
  
  & thead {
    border-bottom: 1px solid #d9d9d9;
  }
  
  & thead th {
    padding-bottom: 8px;
  }
`

const StyledCalendarHeader = styled.div`
  font-weight: bold;
  padding: 8px 8px 0 8px;
  text-align: center;
`

const StyledTable = styled(Table)`
  padding-bottom: 24px;
  
  & th {
    white-space: nowrap;
  }
  
  & td {
    white-space: nowrap;
  }
`

const StyledMap = styled.img`
  border: 1px solid rgb(232, 232, 232);
  border-radius: 4px;
  height: 11rem;
  object-fit: cover;
  width: 100%;
`

const StyledDescriptions = styled(Descriptions)`
  & .ant-descriptions-view {
    border: none !important;
  }
  
  & th {
    padding: 4px 0 !important;
  }
  
  & td {
    padding: 4px 0 4px 8px !important;
  }
`

const StyledCode = styled(Typography.Text)`
  & code {
    margin: 0 !important;
  }
`

export default class Breakdown extends Component {
  static columns = [
    {
      title: <HelpIcon help='Each charging session is assigned a unique id in the Charge system.'>Charging Session ID</HelpIcon>,
      dataIndex: 'id',
      key: 'id',
      render: v => <StyledCode code>{v}</StyledCode>
    },
    {
      title: 'Start At',
      dataIndex: 'start',
      key: 'start',
      render: v => formatDate(v),
    },
    {
      title: 'End At',
      dataIndex: 'end',
      key: 'end',
      render: v => formatDate(v),
    },
    {
      title: <HelpIcon help='Duration of the charging session as HH:MM:SS.'>Duration</HelpIcon>,
      dataIndex: 'duration',
      key: 'duration',
      render: v => v.format(),
      width: 200
    },
    {
      title: <HelpIcon help='Amount of energy delivered to the scooter during this charging session.'>Energy</HelpIcon>,
      dataIndex: 'formattedEnergy',
      key: 'formattedEnergy',
      width: 200
    },
  ]

  static renderExpandedRow(r) {
    return (
      <Row gutter={16}>
        <Col span={14}>
          <StyledDescriptions column={1} bordered size='small'>
            <Descriptions.Item label='ID'><StyledCode code>{r.id}</StyledCode></Descriptions.Item>
            <Descriptions.Item label='Start At'>{formatDate(r.start)}</Descriptions.Item>
            <Descriptions.Item label='End At'>{formatDate(r.end)}</Descriptions.Item>
            <Descriptions.Item label='Location'>{r.address}</Descriptions.Item>
            <Descriptions.Item label='Stall Number'>{r.stallNumber}</Descriptions.Item>
            <Descriptions.Item label='Vehicle ID'>{r.vehicleId ? <StyledCode code>{r.vehicleId}</StyledCode> : 'Unknown'}</Descriptions.Item>
          </StyledDescriptions>
        </Col>
        <Col span={10}>
          <a href={googleMapsUrl(r.coordinates)} target='_blank' rel='noopener noreferrer'><StyledMap src={staticMapUrlSessionDetail(r.coordinates)}/></a>
        </Col>
      </Row>
    )
  }

  constructor(props) {
    super(props)
    this.loadData = this.loadData.bind(this)
  }

  componentDidUpdate() {
    const { yearMonth, day, navigate } = this.props
    if (!moment(`${yearMonth}-${day}`, 'YYYY-MM-DD', true).isValid()) {
      navigate(Dashboard.navigateTab(yearMonth, 'breakdown'))
    }
  }

  async loadData(yearMonth, day) {
    const date = moment(`${yearMonth}-${day}`, 'YYYY-MM-DD', true)
    const data = await dashboardSessionsInspect(date.clone().startOf('day'), date.clone().endOf('day'))

    data.sessions = data.sessions.map(s => {
      s.key = s.id
      s.start = moment(s.start)
      s.end = moment(s.end)
      s.duration = moment.duration(s.end.diff(s.start))
      return s
    })

    return data.sessions
  }

  render() {
    const { yearMonth, day, navigate } = this.props

    const date = moment(`${yearMonth}-${day}`, 'YYYY-MM-DD', true)
    if (!date.isValid()) {
      return <Fragment/>
    }

    return (
      <Fragment>
        <StyledContainer>
          <Calendar
            disabledDate={cellDate => date.format('YYYY-MM') !== cellDate.format('YYYY-MM')}
            fullscreen={false}
            headerRender={() => <StyledCalendarHeader>{date.format('MMMM YYYY')}</StyledCalendarHeader>}
            onChange={date => navigate(`/dashboard/${date.format('YYYY-MM')}/breakdown/${date.format('DD')}`)}
            value={date}
          />
        </StyledContainer>
        <Loads fn={this.loadData}  context='breakdown' variables={[yearMonth, day]}>
          {({ response, error, isPending, isResolved, isRejected }) => (
            <Fragment>
              {(isPending || !response) && <LocalLoader/>}
              {isResolved &&  <StyledTable columns={Breakdown.columns} dataSource={response} expandedRowRender={Breakdown.renderExpandedRow} pagination={false} size='small'/> }
              {isRejected && <Alert type='error' message={error.message}/>}
            </Fragment>
          )}
        </Loads>
      </Fragment>
    )
  }
}