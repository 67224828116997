import {Alert, Table, Typography, Tag, Tooltip} from 'antd'
import React,  { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Loads } from 'react-loads'
import styled from 'styled-components'
import LocalLoader from '../components/common/LocalLoader'
import { dashboardGetPrivateLocations } from '../logic/network'
import {formatSince, isOnline} from '../logic/utils';
import HelpIcon from '../components/common/HelpIcon'

const StyledTable = styled(Table)`
  & th {
    white-space: nowrap;
  }
  
  & td {
    white-space: nowrap;
  }
`

const StyledContainer = styled.div`
  padding: 0 24px 0 24px;
  overflow-y: auto;
  height: calc(100vh - 9rem);
`

const StyledCode = styled(Typography.Text)`
  & code {
    margin: 0 !important;
  }
`

export default class PrivateLocations extends Component {
  static propTypes = {
    partnerId: PropTypes.string.isRequired
  }

  columns = [
    {
      title: 'Location ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id.localeCompare(b.id),
      render: v => <StyledCode code>{v}</StyledCode>,
      width: 130
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      sorter: (a, b) => a.address.localeCompare(b.address),
      render: v => <span>{v}</span>,
      width: 130
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a.type.localeCompare(b.type),
      render: v => <span>{v}</span>,
      width: 130
    },
    {
      title: 'Operating Mode',
      dataIndex: 'operatingMode',
      key: 'operatingMode',
      sorter: (a, b) => a.operatingMode.localeCompare(b.operatingMode),
      render: v => <span>{v}</span>,
      width: 130
    },
    {
      title: <HelpIcon help='Stalls, Bays, Devices'>Channels</HelpIcon>,
      dataIndex: 'metadata',
      key: 'stallsInfo',
      render: v => v ?
          <span>
            <Tooltip placement="top" title="Total"><Tag color='blue'>{v.stallsTotal}</Tag></Tooltip>
            <Tooltip placement="top" title="Available"><Tag color='green'>{v.stallsTotal - v.stallsInUse}</Tag></Tooltip>
            <Tooltip placement="top" title="In use"><Tag color='orange'>{v.stallsInUse}</Tag></Tooltip>
          </span>
          : <Tooltip placement="top" title={"We didn't receive metadata from this location"}><i>unknown</i></Tooltip>,
      width: 130
    },
    {
      title: 'Status',
      dataIndex: 'metadata',
      key: 'status',
      render: v => v ?(
              isOnline(v.lastPing) ?
                  <Tooltip placement="top" title={formatSince(v.lastPing)}><Tag color='green'>Online</Tag></Tooltip>
                  :
                  <Tooltip placement="top" title={formatSince(v.lastPing)}><Tag color='red'>Offline</Tag></Tooltip>)
          : <Tooltip placement="top" title={"We didn't receive metadata from this location"}><i>unknown</i></Tooltip>,
      width: 130
    },
  ]


  async loadLocations(partnerId) {
    return dashboardGetPrivateLocations(partnerId)
  }

  render() {
    const { partnerId } = this.props
    return (
      <StyledContainer>
        <Loads fn={this.loadLocations}  context='privateLocations' variables={[partnerId]}>
          {({ response, error, isPending, isResolved, isRejected }) => (
              <Fragment>
                {isResolved && <StyledTable onChange={this.onChange} rowKey={r => `${r.id}`} columns={this.columns} dataSource={response.locations} size='middle' pagination={{ defaultPageSize: 15}}/>}
                {(isPending || (!response && !isRejected))  && <LocalLoader/>}
                {isRejected && <Alert type='error' message={error.message}/>}
              </Fragment>
          )}
        </Loads>
      </StyledContainer>
    )
  }
}