import { Redirect, Router } from '@reach/router'
import { Hub } from 'aws-amplify'
import React, { Component, Fragment } from 'react'
import update from 'react-addons-update'
import { Loads } from 'react-loads'
import GlobalError from './components/common/GlobalError'
import GlobalLoader from './components/common/GlobalLoader'
import GlobalNotFound from './components/common/GlobalNotFound'
import GlobalUnauthorized from './components/common/GlobalUnauthorized'
import Dashboard from './containers/Dashboard'
import Breakdown from './containers/dashboard/Breakdown'
import Insights from './containers/dashboard/Insights'
import Summary from './containers/dashboard/Summary'
import PrivateLocations from './containers/PrivateLocations'
import Integration from './containers/Integration'
import Invoices from './containers/Invoices'
import Root from './containers/Root'
import Settings from './containers/Settings'
import SignIn from './containers/SignIn'
import { getCurrentUserInfo } from './logic/auth'
import { bugsnagClient } from './logic/config'
import { UnauthorizedError } from './logic/errors'
import { dashboardAuthenticationVerify } from './logic/network'
import { getCurrentYearMonth } from './logic/utils'

export default class App extends Component {
  constructor(props) {
    super(props)
    this.loadData = this.loadData.bind(this)
    this.handleAuthEvent = this.handleAuthEvent.bind(this)

    this.state = {
      refreshCount: 0
    }
  }

  componentDidMount() {
    Hub.listen('auth', this.handleAuthEvent)
  }

  componentWillUnmount() {
    Hub.remove('auth', this.handleAuthEvent)
  }

  async handleAuthEvent(e) {
    if (e.payload && (e.payload.event === 'signIn' || e.payload.event === 'signOut')) {
      if (e.payload.event === 'signIn') {
        bugsnagClient.user = {
          email: e.payload.data.attributes.email
        }
      }

      if (e.payload.event === 'signOut') {
        bugsnagClient.user = null
      }

      this.setState(update(this.state, {
        refreshCount: { $apply: ((x) => x + 1) }
      }))
    }
  }

  async loadData() {
    const data = {
      userInfo: await getCurrentUserInfo(),
      partnerInfo: null
    }

    if (data.userInfo !== null) {
      data.partnerInfo = await dashboardAuthenticationVerify(data.userInfo.attributes.email)

      bugsnagClient.user = {
        email: data.userInfo.attributes.email
      }
    }

    return data
  }

  render() {
    return (
      <Loads fn={this.loadData}  context='app' variables={[this.state.refreshCount]}>
        {({ response, error, isPending, isResolved, isRejected }) => (
          <Fragment>
            {isPending  && <GlobalLoader/>}
            {isResolved && !response.userInfo && <SignIn/>}
            {isResolved && response.userInfo && (
              <Router>
                <Root path='/' auth={response}>
                  <Redirect from='/' to={`dashboard/${getCurrentYearMonth()}/summary`} noThrow/>
                  <Redirect from='dashboard' to={`dashboard/${getCurrentYearMonth()}/summary`} noThrow/>
                  <Redirect from='dashboard/:yearMonth' to={`dashboard/:yearMonth/summary`} noThrow/>
                  <Redirect from='dashboard/:yearMonth/breakdown' to={`dashboard/:yearMonth/breakdown/01`} noThrow/>
                  <Dashboard path='dashboard/:yearMonth'>
                    <Summary path='summary'/>
                    <Insights path='insights'/>
                    <Breakdown path='breakdown/:day'/>
                  </Dashboard>
                  <Invoices path='invoices'/>
                  <Settings path='settings'/>
                  <Integration path='integration'/>

                  <PrivateLocations partnerId={response.partnerInfo.partnerId} path='privateLocations'/>
                  <GlobalNotFound default/>
                </Root>
              </Router>
            )}
            {isRejected && (error instanceof UnauthorizedError ? <GlobalUnauthorized email={error.metadata.email}/> : <GlobalError message={error.message}/>)}
          </Fragment>
        )}
      </Loads>
    )
  }
}
