import { Alert, Col, Empty, Icon, Row, Statistic } from 'antd'
import moment from 'moment'
import React, { Component, Fragment } from 'react'
import { Loads } from 'react-loads'
import styled from 'styled-components'
import HelpIcon from '../../components/common/HelpIcon'
import LocalLoader from '../../components/common/LocalLoader'
import SectionHeader from '../../components/common/SectionHeader'
import DropoffsDistributionChart from '../../components/dashboard/insights/DropoffsDistributionChart'
import LocationsHeatMap from '../../components/dashboard/insights/LocationsHeatMap'
import LocationsTable from '../../components/dashboard/insights/LocationsTable'
import { dashboardInsightsInspect } from '../../logic/network'

const StyledRow = styled(Row)`
  padding: 24px 0 48px 0;
`

const StyledCol = styled(Col)`
  text-align: center;
`

export default class Insights extends Component {
  constructor(props) {
    super(props)

    this.loadData = this.loadData.bind(this)
  }

  async loadData(yearMonth) {
    const date = moment(yearMonth, 'YYYY-MM')
    const data = (await dashboardInsightsInspect(date.clone().startOf('month'), date.clone().endOf('month'), moment.tz.guess())).insights

    data.dropoffsDistribution.map(d => {
      d.hour = `${d.hour}`
      return d
    })

    data.locationsDistribution.map(l => {
      l.key = l.locationId
      return l
    })

    return data
  }

  render() {
    const { yearMonth } = this.props

    return (
      <Loads fn={this.loadData}  context='insights' variables={[yearMonth]}>
        {({ response, error, isPending, isResolved, isRejected, isReloading }) => (
          <Fragment>
            {(isPending || (!response && !isRejected)) && <LocalLoader/>}
            {isResolved && (!response.locationsDistribution || response.locationsDistribution.length === 0) && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            {isResolved && response.locationsDistribution.length > 0 &&
              <Fragment>
                <SectionHeader>Charging Session Statistics</SectionHeader>
                <StyledRow gutter={16}>
                  <StyledCol span={12}>
                    <Statistic title={<HelpIcon help='Average amount of energy delivered to scooters per charging session.'>Avg. Delivered Energy per Session</HelpIcon>} value={response.formattedAverageEnergy} prefix={<Icon type='interaction'/>}/>
                  </StyledCol>
                  <StyledCol span={12}>
                    <Statistic title={<HelpIcon help='Average duration of a charging session.'>Avg. Charging Session Duration</HelpIcon>} value={response.formattedAverageDuration} prefix={<Icon type='thunderbolt'/>}/>
                  </StyledCol>
                </StyledRow>
                <SectionHeader>Charging Sessions Started by Time of Day</SectionHeader>
                <DropoffsDistributionChart data={response.dropoffsDistribution}/>
                <SectionHeader>Charging Sessions by Location</SectionHeader>
                <LocationsHeatMap geoJson={response.locationsHeatMap}/>
                <LocationsTable locations={response.locationsDistribution}/>
              </Fragment>
            }
            {isRejected && <Alert type='error' message={error.message}/>}
          </Fragment>
        )}
      </Loads>
    )
  }
}