import { match } from '@reach/router/lib/utils'
import { Button, Icon, Layout, Menu, PageHeader } from 'antd'
import { Auth } from 'aws-amplify'
import moment from 'moment'
import ordinal from 'ordinal'
import React, { Component } from 'react'
import styled from 'styled-components'
import logoUrl from '../assets/logo.png'

const StyledContainer = styled(Layout)`
  min-height: 100vh;
  min-width: 1200px;
  padding-left: 200px;
`

const StyledSider = styled(Layout.Sider)`
  height: 100vh;
  overflow: auto;
  left: 0;
  position: fixed;
`

const StyledLogo = styled.div`
  background-image: url(${logoUrl});
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  height: 24px;
  margin: 16px;
`

const StyledContent = styled(Layout.Content)`
  background: #fff;
  margin: 0;
  padding: 24px 0 0 0;
`

const StyledHeading = styled(PageHeader)`
  padding: 24px 0;

  & .ant-breadcrumb {
    position: absolute;
    top: 22px;
  }
`

const StyledLayout = styled(Layout)`
  padding: 0 24px 24px 24px;
  z-index: 1000;
`

const StyledUserInfo = styled.span`
  display: inline-block;
  vertical-align: top;
  padding-top: 1px;
`

export default class Root extends Component {
  getBreadcrumbs() {
    const breadcrumbs = ['Charge']

    const summary = match('/dashboard/:yearMonth/summary', this.props.location.pathname)
    const insights = match('/dashboard/:yearMonth/insights', this.props.location.pathname)
    const breakdown = match('/dashboard/:yearMonth/breakdown/:day', this.props.location.pathname)
    const invoices = match('/invoices', this.props.location.pathname)
    const settings = match('/settings', this.props.location.pathname)
    const privateLocations = match('/privateLocations', this.props.location.pathname)
    const integration = match('/integration', this.props.location.pathname)

    if (summary || insights || breakdown) {
      breadcrumbs.push(moment((summary || insights || breakdown).params.yearMonth, 'YYYY-MM').format('MMMM YYYY'))
    }

    if (summary) {
      breadcrumbs.push('Summary')
    } else if (insights) {
      breadcrumbs.push('Insights')
    } else if (breakdown) {
      breadcrumbs.push('Breakdown')
      breadcrumbs.push(ordinal(parseInt(breakdown.params.day)))
    } else if (invoices) {
      breadcrumbs.push('Invoices')
    } else if (privateLocations) {
      breadcrumbs.push('Locations')
    } else if (settings || integration) {
      breadcrumbs.push('Settings')
      if (integration) {
        breadcrumbs.push('Charge Integration Guide')
      }
    } else {
      breadcrumbs.push('Not Found')
    }

    return breadcrumbs.map(b => { return { breadcrumbName: b }})
  }

  render() {
    const { auth, children, navigate } = this.props
    const selectedKeys = this.props['*'] ? [this.props['*'].split('/')[0].replace('integration', 'settings')] : []
    const date = moment()

    return (
      <StyledContainer path='/'>
        <StyledSider>
          <StyledLogo alt='Charge'/>
          <Menu theme='dark' mode='inline' selectedKeys={selectedKeys}>
            <Menu.Item key='dashboard' onClick={() => navigate(`/dashboard/${date.format('YYYY-MM')}/summary`)}><span><Icon type='dashboard'/><span>Dashboard</span></span></Menu.Item>
            <Menu.Item key='privateLocations' onClick={() => navigate('/privateLocations')}><span><Icon type='environment'/><span>Locations</span></span></Menu.Item>
            <Menu.Item key='invoices' onClick={() => navigate('/invoices')}><span><Icon type='dollar'/><span>Invoices</span></span></Menu.Item>
            <Menu.Item key='settings' onClick={() => navigate('/settings')}><span><Icon type='control'/><span>Settings</span></span></Menu.Item>
          </Menu>
        </StyledSider>
        <StyledLayout>
          <StyledHeading breadcrumb={{itemRender: (route) => <span>{route.breadcrumbName}</span>, routes: this.getBreadcrumbs()}} extra={[
            <StyledUserInfo key='userInfo'>{auth.partnerInfo.partnerName} &middot; {auth.userInfo.attributes.email}</StyledUserInfo>,
            <Button key='signOut' onClick={() => Auth.signOut()} size='small'>Sign Out</Button>
          ]} title={null}/>
          <StyledContent>
            {children}
          </StyledContent>
        </StyledLayout>
      </StyledContainer>
    )
  }
}