import { Table, Typography } from 'antd'
import React, { Component } from 'react'
import styled from 'styled-components'
import SectionHeader from '../components/common/SectionHeader'

const StyledContainer = styled.div`
  padding: 0 24px 0 24px;
  overflow-y: auto;
  height: calc(100vh - 9rem);  
  & p {
    text-align: justify;
  }
`

const StyledSpacer = styled.div`
  max-width: 55rem;
`

const StyledSubHeader = styled.p`
  font-style: italic;
`

const StyledCode = styled.pre`
  background-color: rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 3px;
  font-size: 12px;
  padding: 8px;
`

export default class Integration extends Component {
  static errorsColumns = [
    {
      title: 'Status Code',
      dataIndex: 'statusCode',
      key: 'statusCode',
      width: 100
    },
    {
      title: 'Error Detail',
      dataIndex: 'errorDetail',
      key: 'errorDetail',
      width: 200
    },
    {
      title: 'Description / Resolution',
      dataIndex: 'description',
      key: 'description'
    }
  ]

  static paramsColumns = [
    {
      title: 'Parameter',
      dataIndex: 'parameter',
      key: 'parameter',
      width: 150
    },
    {
      title: 'Examples',
      dataIndex: 'examples',
      key: 'examples',
      width: 150
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    }
  ]

  static behaviorsColumns = [
    {
      title: 'Stall Number',
      dataIndex: 'stallNumber',
      key: 'stallNumber',
      width: 100
    },
    {
      title: 'Behavior',
      dataIndex: 'behavior',
      key: 'behavior'
    }
  ]

  render() {
    return (
      <StyledContainer>
        <StyledSpacer>
          <SectionHeader>Charge Integration Guide</SectionHeader>
          <StyledSubHeader>Revision 1.1 &middot; Updated on Jan 16th, 2020</StyledSubHeader>
          <p style={{textAlign: 'justify'}}>Charge provides a restful API for third-party ridesharing apps wishing to access its network for scooter charging stations. The API allows to search for charging stations based on location, and to coordinate a scooter drop-off (i.e. the process of parking a scooter at a station and initiating charging).</p>
          <SectionHeader>API Basics</SectionHeader>
          <p>The Charge API is a fairly standard RESTful HTTP JSON API. All endpoints are available exclusively over HTTPS.</p>
          <StyledSubHeader>Environments</StyledSubHeader>
          <p>Configuration details for the production environment, connected to physical charging stations, will be disclosed prior to the launch of a partnership. Meanwhile, we provide access to a simulated sandbox environment which can be used to develop the third-party side of the integration as well as for e2e testing. The sandbox environment is described in detail later in this document.</p>
          <StyledSubHeader>Authentication</StyledSubHeader>
          <p>All API endpoints require an API key to be provided via the <Typography.Text code>X-Api-Key</Typography.Text> HTTP header.</p>
          <StyledSubHeader>Error Messages</StyledSubHeader>
          <p>Standard error messages are returned as in the following example:</p>
          <StyledCode>{JSON.stringify({
            "statusCode": 404,
            "errorDetail": "dropoff-not-found"
          }, '', '  ')}</StyledCode>
          <p>The <Typography.Text code>statusCode</Typography.Text> field always matches the HTTP status code returned with the response. The <Typography.Text code>errorDetail</Typography.Text> field is optional, and can be used to programmatically handle specific classes of errors. Any error that does not include an <Typography.Text code>errorDetail</Typography.Text> value can be treated as a generic error which does not require special handling. The following table describes the common errors that might be returned by any endpoint. Endpoint-specific errors are described in the appropriate sections of this document.</p>
          <Table
            columns={Integration.errorsColumns}
            dataSource={[
              {
                statusCode: 403,
                errorDetail: 'invalid-api-key',
                description: 'A valid API key must be provided via the X-Api-Key header.'
              },
              {
                statusCode: 429,
                errorDetail: 'throttled',
                description: 'Too many requests, reduce traffic.'
              },
              {
                statusCode: 400,
                errorDetail: 'unexpected-body',
                description: 'This request should not have a body. '
              },
              {
                statusCode: 400,
                errorDetail: 'missing body',
                description: 'This request should have a body.'
              },
              {
                statusCode: 400,
                errorDetail: 'malformed-body',
                description: 'The provided request body is not valid JSON or contains unexpected fields.'
              },
              {
                statusCode: 400,
                errorDetail: 'unknown-parameter',
                description: 'An unknown query string parameter was provided.'
              },
            ]}
            pagination={false}
            size='small'
          />
          <br/>
          <SectionHeader>Searching for Stations</SectionHeader>
          <p>Stations is searched using the <Typography.Text code>GET /v1/locations</Typography.Text> endpoint. The endpoint accepts the following query string parameters (parameters marked with an asterisk are required):</p>
          <Table
            columns={Integration.paramsColumns}
            dataSource={[
              {
                parameter: 'type*',
                examples: 'station',
                description: 'Currently only charging stations are supported, this parameter is provided for forward compatibility with virtual parking and other products.'
              },
              {
                parameter: 'queryClosestTo*',
                examples: '"40.2,-73.56,1.5",  "40.72,-73.988"',
                description: 'Query by proximity to a set of coordinates. Currently this is required and the only query method available. Additional query methods (e.g. bounding box) might  be added in the future. The value consists of two or three comma-separated values. The first two (required) are latitude and longitude, while the third one (optional) is the  maximum distance in Km from the coordinates (default 5, minimum 1, maximum 10).'
              },
              {
                parameter: 'limit',
                examples: '10',
                description: 'Maximum number of locations to return. Default 10, minimum 1, maximum 100.'
              }
            ]}
            pagination={false}
            size='small'
          />
          <br/>
          <p>The response includes an array of locations matching the query criteria. If no locations are found, an empty array is returned. The following is an example response.</p>
          <StyledCode>{JSON.stringify({
            "locations": [
              {
                "id": "ddc3d579-4ef3-4ca6-9bb7-1fa26d6e0da1",
                "type": "station",
                "coordinates": {
                  "lat": 40.74866,
                  "lng": -73.985614
                },
                "address": "350 Fifth Avenue, New York, NY 10118-0110",
                "stallsTotal": 12,
                "stallsAvailable": 9
              },
              {
                "id": "ad97c497-db60-4bd2-a801-303f18de70d1",
                "type": "station",
                "coordinates": {
                  "lat": 40.750942,
                  "lng": -73.989757
                },
                "address": "151 W 34th Street, New York, NY 10001-2101",
                "stallsTotal": 12,
                "stallsAvailable": 5
              }
            ]
          }, '', '  ')}</StyledCode>
          <p>The following table describes the endpoint-specific error messages.</p>
          <Table
            columns={Integration.errorsColumns}
            dataSource={[
              {
                statusCode: 400,
                errorDetail: 'missing-type',
                description: 'Missing "type" query string parameter.'
              },
              {
                statusCode: 400,
                errorDetail: 'invalid-type',
                description: 'Invalid "type" query string parameter.'
              },
              {
                statusCode: 400,
                errorDetail: 'invalid-limit',
                description: 'Invalid "limit" query string parameter.'
              },
              {
                statusCode: 400,
                errorDetail: 'missing-query',
                description: 'Missing "queryClosestTo" parameter.'
              },
              {
                statusCode: 400,
                errorDetail: 'invalid-query',
                description: 'Invalid "queryClosestTo" parameter.'
              }
            ]}
            pagination={false}
            size='small'
          />
          <br/>
          <SectionHeader>Initiating a Drop-off</SectionHeader>
          <p>Drop-offs are initiated using the <Typography.Text code>POST /v1/dropoffs</Typography.Text> endpoint. The request body must contain a drop-off method, currently only <Typography.Text code>byCoordinatesAndStallNumber</Typography.Text> is supported. This method requires a rider to bring the scooter to a station and select a charging stall, providing its number in the ridesharing app. The following is an example request body.</p>
          <StyledCode>{JSON.stringify({
            "byCoordinatesAndStallNumber": {
              "coordinates": {
                "lat": 40.74865,
                "lng": -73.985611
              },
              "stallNumber": 1
            }
          }, '', '  ')}</StyledCode>
          <p>If everything goes well, the charging stall will now be enabled, its LED light will turn on, and the ridesharing app will instruct the rider to plug in the scooter. The following is an example success response.</p>
          <StyledCode>{JSON.stringify({
            "dropoff": {
              "id": "b431c7b5-6bc5-78b1-a428-b3ab0932da42",
              "status": "pending"
            },
            "location": {
              "id": "ddc3d579-4ef3-4ca6-9bb7-1fa26d6e0da1",
              "type": "station",
              "coordinates": {
                "lat": 40.74866,
                "lng": -73.985614
              },
              "address": "350 Fifth Avenue, New York, NY 10118-0110",
              "stallsTotal": 12,
              "stallsAvailable": 9
            }
          }, '', '  ')}</StyledCode>
          <p>Note the <Typography.Text code>dropoff</Typography.Text> object. Its <Typography.Text code>id</Typography.Text> field provides a unique identifier which can be used to later verify the status of the drop-off as described in the next section. The <Typography.Text code>status</Typography.Text> field will always be <Typography.Text code>pending</Typography.Text> in this response.</p>
          <p>The following table describes the endpoint-specific error messages.</p>
          <Table
            columns={Integration.errorsColumns}
            dataSource={[
              {
                statusCode: 400,
                errorDetail: 'missing-dropoff-method',
                description: 'Missing "byCoordinatesAndStallNumber" field.'
              },
              {
                statusCode: 400,
                errorDetail: 'missing-coordinates',
                description: 'Missing "coordinates" field.'
              },
              {
                statusCode: 400,
                errorDetail: 'invalid-coordinates',
                description: 'Invalid "coordinates" field.'
              },
              {
                statusCode: 400,
                errorDetail: 'invalid-stall-number',
                description: 'Invalid "stallNumber" field.'
              },
              {
                statusCode: 404,
                errorDetail: 'nearby-station-not-found',
                description: 'No station found in proximity of the given coordinates.'
              },
              {
                statusCode: 409,
                errorDetail: 'stall-not-available',
                description: 'The selected charging stall is not available.'
              }
            ]}
            pagination={false}
            size='small'
          />
          <br/>
          <SectionHeader>Verifying a Drop-Off</SectionHeader>
          <p>The status of a drop-off can be checked using the <Typography.Text code>GET /v1/dropoff/&lt;dropoffId&gt;</Typography.Text> endpoint, where <Typography.Text code>&lt;dropoffId&gt;</Typography.Text> is the id returned by the request in the previous session. Clients can generally poll this endpoint at 1s - 5 s intervals while performing a drop-off, in order to show a success message to the rider and/or terminate their ride in the third-party system. The following is an example successful response.</p>
          <StyledCode>{JSON.stringify({
            "dropoff": {
              "id": "b431ccfd-6bc5-78b1-a428-b3aae39fb03c",
              "status": "completed"
            },
            "location": {
              "id": "ddc3d579-4ef3-4ca6-9bb7-1fa26d6e0da1",
              "type": "station",
              "coordinates": {
                "lat": 40.74866,
                "lng": -73.985614
              },
              "address": "350 Fifth Avenue, New York, NY 10118-0110",
              "stallsTotal": 12,
              "stallsAvailable": 9
            }
          }, '', '  ')}</StyledCode>
          <p>Note the <Typography.Text code>dropoff.status</Typography.Text> field. There are three possible values:</p>
          <ul>
            <li><Typography.Text code>pending</Typography.Text>: Returned while a drop-off has been initiated, but the scooter has not been plugged in yet.</li>
            <li><Typography.Text code>completed</Typography.Text>: Returned after a scooter has been plugged in.</li>
            <li><Typography.Text code>expired</Typography.Text>: Returned after 3 minutes from initiation if no scooter was plugged in.</li>
          </ul>
          <p>The following table describes the endpoint-specific error messages.</p>
          <Table
            columns={Integration.errorsColumns}
            dataSource={[
              {
                statusCode: 404,
                errorDetail: 'dropoff-not-found',
                description: 'The provided drop-off id is invalid or was not found in the system.'
              }
            ]}
            pagination={false}
            size='small'
          />
          <br/>
          <SectionHeader>Using the Sandbox Environment</SectionHeader>
          <p>The sandbox environment is available at <Typography.Text code>https://sandbox.prod.charge.us</Typography.Text>. It contains a database of simulated test stations and its behavior, while mimicking the production environment, is modified for testing and development purposes. Most notably:</p>
          <ul>
            <li>The number of available stalls in stations is not updated at drop-off.</li>
            <li>The physical interaction with stations is simulated, with different outcomes depending on the stall number selected at drop-off.</li>
          </ul>
          <p>The following table describes the programmed behaviors by stall number.</p>
          <Table
            columns={Integration.behaviorsColumns}
            dataSource={[
              {
                stallNumber: 1,
                behavior: 'A scooter will be plugged 1m after drop-off, transitioning the status to "completed".'
              },
              {
                stallNumber: 2,
                behavior: 'The stall is not available.',
              },
              {
                stallNumber: 3,
                behavior: 'No scooter will be plugged in after drop-off. The status will transition to "expired" after 3m.',
              },
              {
                stallNumber: 4,
                behavior: 'The drop-off will fail with a generic unexpected error.',
              },
              {
                stallNumber: 5,
                behavior: 'The drop-off will fail with a 429 throttled error.',
              }
            ]}
            pagination={false}
            size='small'
          />
          <br/>
          <p>The other stall numbers will return a <Typography.Text code>501 Not Implemented</Typography.Text> error and are reserved for future use.</p>
        </StyledSpacer>
      </StyledContainer>
    )
  }
}