import { Link } from '@reach/router'
import { Alert, Button, Card, Col, Checkbox, message, Row, Table } from 'antd'
import countryCodeEmoji from 'country-code-emoji'
import React, { Component, Fragment } from 'react'
import update from 'react-addons-update'
import nl2br from 'react-nl2br'
import { Loads } from 'react-loads'
import styled from 'styled-components'
import AddAccessEmail from './settings/AddAccessEmail'
import AddNotificationEmail from './settings/AddNotificationEmail'
import AddContractorsUser from './settings/AddContractorsUser'
import LocalLoader from '../components/common/LocalLoader'
import ActionConfirm from '../components/common/ActionConfirm'
import Reveal from '../components/common/Reveal'
import SectionHeader from '../components/common/SectionHeader'
import { dashboardPartnerInspect, dashboardPartnerUpdate } from '../logic/network'

const StyledContainer = styled.div`
  padding: 0 24px 0 24px;
  overflow-y: auto;
  height: calc(100vh - 9rem);
`

const ActionButton = styled(Button)`
  padding-left: 0 !important;
`

const StyledCards = styled.div`
  margin-bottom: -16px;
  margin-right: -16px;

  & .ant-card {
    width: 250px;
    float: left;
    margin-right: 16px;
    margin-bottom: 16px;
  }
  
  &::after {
    content: "";
    clear: both;
    display: table;
  }
`

export default class Settings extends Component {
  dashboardUsersColumns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: 'actions',
      render: (v) => <ActionConfirm title={`Remove '${v.email}'?`} help='It will no longer be possible to access this dashboard using this email address.' onConfirm={() => this.handleRemoveAccessEmail(v.email)}><ActionButton type='link' size='small'>Remove</ActionButton></ActionConfirm>,
      width: 100
    }
  ]

  notificationEmailsColumns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Invoices',
      dataIndex: 'invoices',
      key: 'invoices',
      render: (v) => <Checkbox checked={v} disabled={true}/>,
      width: 200
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: 'actions',
      render: (v) => <ActionConfirm title={`Remove '${v.email}'?`} help='This email address will no longer receive account-wide notifications.' onConfirm={() => this.handleRemoveNotificationEmail(v.email)}><ActionButton type='link' size='small'>Remove</ActionButton></ActionConfirm>,
      width: 100
    }
  ]

  contractorsUsersColumns = [
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: 200,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: 'actions',
      render: (v) => <ActionConfirm title={`Remove '${v.phoneNumber}' (${v.name})?`} help='It will no longer be possible to access the contractors tool using this phone number.' onConfirm={() => this.handleRemoveContractorsUser(v.phoneNumber)}><ActionButton type='link' size='small'>Remove</ActionButton></ActionConfirm>,
      width: 120
    }
  ]

  constructor(props) {
    super(props)

    this.loadData = this.loadData.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleDone = this.handleDone.bind(this)
    this.handleRemoveAccessEmail = this.handleRemoveAccessEmail.bind(this)
    this.handleRemoveNotificationEmail = this.handleRemoveNotificationEmail.bind(this)

    this.state = {
      refreshCount: 0,
      isAddAccessEmailOpen: false,
      isAddNotificationEmailOpen: false,
      isAddContractorsUserOpen: false,
    }
  }

  async loadData() {
    return await dashboardPartnerInspect()
  }

  handleOpen(target) {
    this.setState(update(this.state, {
      [target]: { $set: true }
    }))
  }

  handleDone(refresh) {
    this.setState(update(this.state, {
      refreshCount: { $apply: v => refresh ? v + 1 : v },
      isAddAccessEmailOpen: { $set: false },
      isAddNotificationEmailOpen: { $set: false },
      isAddContractorsUserOpen: { $set: false },
    }))
  }

  async handleRemoveAccessEmail(email) {
    try {
      await dashboardPartnerUpdate({ removeDashboardUser: email })

      this.setState(update(this.state, {
        refreshCount: { $apply: v => v + 1 }
      }))
    } catch (err) {
      message.error(err.message)
    }
  }

  async handleRemoveNotificationEmail(email) {
    try {
      await dashboardPartnerUpdate({ removeNotificationEmail: email })

      this.setState(update(this.state, {
        refreshCount: { $apply: v => v + 1 }
      }))
    } catch (err) {
      message.error(err.message)
    }
  }

  async handleRemoveContractorsUser(phoneNumber) {
    try {
      await dashboardPartnerUpdate({ removeContractorsUser: phoneNumber })

      this.setState(update(this.state, {
        refreshCount: { $apply: v => v + 1 }
      }))
    } catch (err) {
      message.error(err.message)
    }
  }

  render() {
    const { refreshCount, isAddAccessEmailOpen, isAddNotificationEmailOpen, isAddContractorsUserOpen } = this.state

    return (
      <Fragment>
        <Loads fn={this.loadData}  context='settings' variables={[refreshCount]}>
          {({ response, error, isPending, isResolved, isRejected }) => (
            <StyledContainer>
              {isPending && <LocalLoader/>}
              {isResolved &&
                <Fragment>
                  <SectionHeader>Partner Entities</SectionHeader>
                  <StyledCards>
                    {response.partner.entities.map(e =>
                      <Card extra={countryCodeEmoji(e.countryCode)} key={e.id} size='small' title={e.name}>
                        <p><i>Tax Id</i><br/>{e.taxId}</p>
                        <p><i>Address</i><br/>{nl2br(e.address)}</p>
                      </Card>
                    )}
                  </StyledCards>
                  <br/><br/>

                  <SectionHeader>API Access</SectionHeader>
                  <Row gutter={16}>
                    <Col xl={12} span={24}>
                      <Card title='Sandbox Environment' size='small'>
                        <Row>
                          <Col span={4}><p><i>Base Url</i></p></Col>
                          <Col span={20}>{response.integration.sandboxBaseUrl}</Col>
                        </Row>
                        <Row>
                          <Col span={4}><i>Api Key</i></Col>
                          <Col span={20}><Reveal secret={response.integration.sandboxApiKey}/></Col>
                        </Row>
                      </Card>
                      <br/>
                    </Col>
                    <Col xl={12} span={24}>
                      <Card title='Live Environment' size='small'>
                        <Row>
                          <Col span={4}><p><i>Base Url</i></p></Col>
                          <Col span={20}>{response.integration.liveBaseUrl}</Col>
                        </Row>
                        <Row>
                          <Col span={4}><i>Api Key</i></Col>
                          <Col span={20}><Reveal secret={response.integration.liveApiKey}/></Col>
                        </Row>
                      </Card>
                      <br/>
                    </Col>
                  </Row>
                  <Link to='/integration'>Read the Charge Integration Guide</Link>&nbsp;&nbsp;&middot;&nbsp;&nbsp;<a href='https://status.charge.us' target='_blank' rel='noopener noreferrer'>Check the Status Page</a>
                  <br/>
                  <br/>

                  <SectionHeader>Dashboard Access</SectionHeader>
                  <p>Anyone with access to these email addresses can access this dashboard.</p>
                  <Table columns={this.dashboardUsersColumns} dataSource={response.partner.dashboardUsers.map(u => { u.key = u.email; return u })} pagination={false} size='small'/>
                  <br/>
                  <Button type='primary' onClick={() => this.handleOpen('isAddAccessEmailOpen')}>Add Access Email</Button>
                  <br/><br/>

                  <SectionHeader>Notifications</SectionHeader>
                  <p>The following email addresses receive account-wide notifications, for example invoices.</p>
                  <Table columns={this.notificationEmailsColumns} dataSource={response.partner.notifications.map(n => { n.key = n.email; return n })} pagination={false} size='small'/>
                  <br/>
                  <Button type='primary' onClick={() => this.handleOpen('isAddNotificationEmailOpen')}>Add Notification Email</Button>
                  <br/><br/>

                  <SectionHeader>Contractors Tool Users</SectionHeader>
                  <p>The contractors tool is a simple mobile app (for <a target='_blank' rel='noreferrer noopener' href='https://testflight.apple.com/join/r9Y0V6KG'>iOS</a> and <a target='_blank' rel='noreferrer noopener' href='https://play.google.com/store/apps/details?id=com.getcharged.charge.contractor'>Android</a>) that allows trusted partners to drop scooters off at stations for charging.</p>
                  <p>Anyone with access to these phone numbers can access the contractors tool.</p>
                  <Table rowKey={r => r.phoneNumber} columns={this.contractorsUsersColumns} dataSource={response.contractorsUsers} pagination={false} size='small'/>
                  <br/>
                  <Button type='primary' onClick={() => this.handleOpen('isAddContractorsUserOpen')}>Add Contractors Tool User</Button>

                </Fragment>
              }
              {isRejected && <Alert type='error' message={error.message}/>}
            </StyledContainer>
          )}
        </Loads>
        {isAddAccessEmailOpen && <AddAccessEmail onDone={this.handleDone}/>}
        {isAddNotificationEmailOpen && <AddNotificationEmail onDone={this.handleDone}/>}
        {isAddContractorsUserOpen && <AddContractorsUser onDone={this.handleDone}/>}
      </Fragment>
    )
  }
}