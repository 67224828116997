import { Button, Modal } from 'antd'
import { Auth } from 'aws-amplify'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

export default class GlobalUnauthorized extends Component {
  static propTypes = {
    email: PropTypes.string.isRequired
  }

  render() {
    return (
      <Modal transitionName='none' centered closable={false} footer={null} title='Charge Partner Dashboard' visible={true}>
        <p>You are signed in as <i>{this.props.email}</i>, but you are not authorized to access the Charge Partner Dashboard:</p>
        <ul>
          <li>If you believe this to be a mistake, please <a href='mailto:support@charge.us'>contact us</a></li>
          <li>If you wish to try with a different email address, please <Button type='link' onClick={() => Auth.signOut()} style={{padding: '0'}}>sign out</Button></li>
        </ul>
      </Modal>
    )
  }
}