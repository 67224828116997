import { Alert, Col, Empty, Icon, Row, Statistic } from 'antd'
import moment from 'moment'
import 'moment-timezone'
import React, { Component, Fragment } from 'react'
import { Loads } from 'react-loads'
import styled from 'styled-components'
import HelpIcon from '../../components/common/HelpIcon'
import LocalLoader from '../../components/common/LocalLoader'
import DailyDurationChart from '../../components/dashboard/summary/DailyDurationChart'
import DailyEnergyChart from '../../components/dashboard/summary/DailyEnergyChart'
import DailySessionsChart from '../../components/dashboard/summary/DailySessionsChart'
import { dashboardSummaryInspect } from '../../logic/network'

const StyledRow = styled(Row)`
  padding: 48px 0 48px 0;
`

const StyledCol = styled(Col)`
  text-align: center;
`

export default class Summary extends Component {
  constructor(props) {
    super(props)

    this.loadData = this.loadData.bind(this)
  }

  async loadData(yearMonth) {
    const date = moment(yearMonth, 'YYYY-MM')
    const data = (await dashboardSummaryInspect(date.clone().startOf('month'), date.clone().endOf('month'), moment.tz.guess())).summary

    data.daily = data.daily.map(daily => {
      daily.day = daily.day.split('-')[2]
      return daily
    })

    return data
  }

  render() {
    const { yearMonth } = this.props

    return (
      <Loads fn={this.loadData}  context='summary' variables={[yearMonth]}>
        {({ response, error, isPending, isResolved, isRejected }) => (
          <Fragment>
            {(isPending || (!response && !isRejected))  && <LocalLoader/>}
            {isResolved && response.totalSessions === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            {isResolved && response.totalSessions > 0 &&
              <Fragment>
                <StyledRow gutter={16}>
                  <StyledCol span={8}>
                    <Statistic title={<HelpIcon help='Number of charging sessions in the selected period. A charging session starts when a scooter is plugged in to a station, and ends when it is unplugged or fully charged.'>Charging Sessions</HelpIcon>} value={response.totalSessions} prefix={<Icon type='interaction'/>}/>
                  </StyledCol>
                  <StyledCol span={8}>
                    <Statistic title={<HelpIcon help='Total amount of energy delivered to scooters in the selected period.'>Delivered Energy</HelpIcon>} value={response.formattedTotalEnergy} prefix={<Icon type='thunderbolt'/>}/>
                  </StyledCol>
                  <StyledCol span={8}>
                    <Statistic title={<HelpIcon help='Sum of the durations of all charging sessions in the selected period.'>Cumulative Duration</HelpIcon>} value={response.formattedTotalDuration} prefix={<Icon type='clock-circle'/>}/>
                  </StyledCol>
                </StyledRow>
                <DailySessionsChart data={response.daily} yearMonth={yearMonth}/>
                <DailyEnergyChart data={response.daily} yearMonth={yearMonth}/>
                <DailyDurationChart data={response.daily} yearMonth={yearMonth}/>
              </Fragment>
            }
            {isRejected && <Alert type='error' message={error.message}/>}
          </Fragment>
        )}
      </Loads>
    )
  }
}