import { Button } from 'antd'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styled from 'styled-components'

const StyledButton = styled(Button)`
  margin: 0;
  padding: 0;
  height: 0;
`

export default class Reveal extends Component {
  static propTypes = {
    secret: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)

    this.state = {
      revealed: false
    }
  }

  handleClick() {
    this.setState({
      revealed: true
    })
  }

  render() {
    const { secret } = this.props
    const { revealed } = this.state

    if (!revealed) {
      return <StyledButton type='link' onClick={this.handleClick}>Reveal</StyledButton>
    }

    return <span>{secret}</span>
  }
}