import { Button, Form, Input, Modal } from 'antd'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import update from 'react-addons-update'
import styled from 'styled-components'
import { RequestError } from '../../logic/errors'
import { dashboardPartnerUpdate } from '../../logic/network'

const StyledForm = styled(Form)`
  & .ant-form-item {
    margin: 0;
  }
`

const StyledFormFooter = styled(Form.Item)`
  padding-top: 16px;
  text-align: center;
  
  & button {
    width: 6rem;
  }
`

export default class AddConsoleUser extends Component {
    static propTypes = {
        onDone: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props)

        this.handleCancel = this.handleCancel.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)

        this.state = {
            errorMessage: null,
            loading: false,
            phoneNumberValue: '',
            nameValue: ''
        }
    }

    handleCancel() {
        this.props.onDone(false)
    }

    async handleSubmit(e) {
        const { phoneNumberValue, nameValue } = this.state
        e.preventDefault()

        this.setState(update(this.state, {
            errorMessage: { $set: null },
            loading: { $set: true }
        }))

        try {
            await dashboardPartnerUpdate({
                addContractorsUser: {
                    phoneNumber: phoneNumberValue,
                    name: nameValue
                }
            })
            this.props.onDone(true)
        } catch (err) {
            const errorMessage = (err instanceof RequestError && err.status === 400)
                ? 'Please provide a valid phone number.'
                : (err instanceof RequestError && err.status === 409)
                    ? 'This phone number is already in use.'
                    : err.message

            this.setState(update(this.state, {
                errorMessage: { $set: errorMessage },
                loading: { $set: false }
            }))
        }
    }

    handleChange(field, value) {
        this.setState(update(this.state, {
            errorMessage: { $set: null },
            [field + 'Value']: { $set: value }
        }))
    }

    render() {
        const { errorMessage, loading, phoneNumberValue, nameValue } = this.state

        return (
            <Modal centered closable={true} footer={null} title='Add Contractors Tool User' visible={true} onCancel={this.handleCancel} transitionName='none'>
                <p>Anyone with access to this phone number will be able to access the contractors tool.</p>
                <StyledForm layout='vertical' onSubmit={this.handleSubmit}>
                    <Form.Item label='Phone Number' help={errorMessage} validateStatus={errorMessage ? 'error' : null}>
                        <Input disabled={loading} onChange={e => this.handleChange('phoneNumber', e.target.value)} placeholder='+12223334444' value={phoneNumberValue}/>
                    </Form.Item>
                    <Form.Item label='Name'>
                        <Input disabled={loading} onChange={e => this.handleChange('name', e.target.value)} placeholder='Peter' value={nameValue}/>
                    </Form.Item>
                    <StyledFormFooter>
                        <Button disabled={!phoneNumberValue.length || !nameValue.length} htmlType='submit' loading={loading} type='primary'>Add</Button>
                    </StyledFormFooter>
                </StyledForm>
            </Modal>
        )
    }
}