import { Alert, Table } from 'antd'
import React, { Component } from 'react'
import { Loads } from 'react-loads'
import styled from 'styled-components'
import HelpIcon from '../components/common/HelpIcon'
import LocalLoader from "../components/common/LocalLoader";
import { dashboardInvoicesInspect } from '../logic/network'
import countryCodeEmoji from "country-code-emoji";

const StyledContainer = styled.div`
  padding: 0 24px 0 24px;
  overflow-y: auto;
  height: calc(100vh - 9rem);
`

const StyledTable = styled(Table)`
  & th {
    white-space: nowrap;
  }
  
  & td {
    white-space: nowrap;
  }
`

export default class Invoices extends Component {
  static columns = [
    {
      title: <HelpIcon help='Invoices are issued at month end and are due on the 5th of the following month.'>Billing Month</HelpIcon>,
      dataIndex: 'formattedYearMonth',
      key: 'formattedYearMonth',
      width: 190
    },
    {
      title:  <HelpIcon help='Each partner entity is billed independently using local currency, rates, and rules.'>Recipient Entity</HelpIcon>,
      dataIndex: 'partnerEntityName',
      key: 'partnerEntityName',
      render: (v, r) => <span>{v}&nbsp;&nbsp;{countryCodeEmoji(r.partnerEntityCountryCode)}</span>
    },
    {
      title: <HelpIcon help='Invoice total including all applicable taxes and fees.'>Total</HelpIcon>,
      dataIndex: 'formattedTotal',
      key: 'formattedTotal',
      width: 190
    },
    {
      title: <HelpIcon help='Payment instructions are included in the invoice PDF. It can take a few days for payments to be reflected here.'>Paid</HelpIcon>,
      dataIndex: 'paid',
      key: 'paid',
      render: (v) => v ? 'Yes' : 'No',
      width: 190
    },
    {
      title: <HelpIcon help='Download the invoice as PDF. These links expire after 6 hours, please refresh this page to get new links.'>PDF</HelpIcon>,
      dataIndex: 'signedDownloadLink',
      key: 'signedDownloadLink',
      render: (v) => <a target='_blank' rel='noopener noreferrer' href={v}>Link</a>,
      width: 190
    }
  ]

  constructor(props) {
    super(props)
    this.loadData = this.loadData.bind(this)
  }

  async loadData() {
    const data = await dashboardInvoicesInspect()

    data.invoices = data.invoices.map(i => {
      i.key = `${i.yearMonth}-${i.partnerId}-${i.partnerEntityId}`
      return i
    })

    return data.invoices
  }

  render() {
    return (
      <Loads fn={this.loadData}  context='invoices'>
        {({ response, error, isPending, isResolved, isRejected }) => (
          <StyledContainer>
            {isPending && <LocalLoader/>}
            {isResolved && <StyledTable columns={Invoices.columns} dataSource={response} pagination={false} size='small'/>}
            {isRejected && <Alert type='error' message={error.message}/>}
          </StyledContainer>
        )}
      </Loads>
    )
  }
}