import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'
import mapboxGl from 'mapbox-gl'
import Amplify from 'aws-amplify'
import React from 'react'

const config = {
    stage: process.env.REACT_APP_STAGE,
    internalApiBaseUrl: process.env.REACT_APP_INTERNAL_API_BASE_URL,
    cognitoUserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    cognitoUserPoolClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
    mapboxAccessToken: 'pk.eyJ1IjoiY2hhcmdlLWFjY291bnRzIiwiYSI6ImNrM3dpNnM5NzBsd3MzcWxwbXluejViengifQ.G603jUHSeGGi9w0bJCrOcw',
    bugsnagApiKey: 'be3a05442458509b8956d718c1f094c5'
}

export default config

export const bugsnagClient = bugsnag(config.bugsnagApiKey, {
    appType: 'partner-dashboard',
    notifyReleaseStages: ['staging', 'prod'],
    releaseStage: (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 'development' : config.stage
})

bugsnagClient.use(bugsnagReact, React)
mapboxGl.accessToken = config.mapboxAccessToken

Amplify.configure({
    Analytics: {
        disabled: true
    },
    Auth: {
        region: 'us-east-1',
        userPoolId: config.cognitoUserPoolId,
        userPoolWebClientId: config.cognitoUserPoolClientId,
    }
})
