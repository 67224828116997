import moment from 'moment'
import config from './config'

export function getCurrentYearMonth() {
    return moment().format('YYYY-MM')
}

export function formatDate(strOrMoment) {
    if (typeof strOrMoment === 'string') {
        strOrMoment = moment(strOrMoment)
    }
    return `${strOrMoment.format('L [at] LTS')}`
}

export function staticMapUrlSessionDetail(center) {
    return `https://api.mapbox.com/styles/v1/mapbox/light-v10/static/pin-s+36da96(${center.lng},${center.lat})/${center.lng},${center.lat},14,0,0/500x500@2x?logo=false&access_token=${config.mapboxAccessToken}`
}

export function googleMapsUrl(center) {
    return `https://www.google.com/maps/search/?api=1&query=${center.lat},${center.lng}`
}

export function formatSince(strOrMoment) {
    if (typeof strOrMoment === 'string') {
        strOrMoment = moment(strOrMoment)
    }
    return strOrMoment.fromNow()
}

export function isOnline(strOrMoment) {
    let isOnline = false
    if (typeof strOrMoment === 'string') {
        strOrMoment = moment(strOrMoment)
    }

    let now = moment(new Date());
    let duration = moment.duration(now.diff(strOrMoment));
    let minutes = duration.asMinutes();

    if (minutes <= 15 ) {
        isOnline = true
    }

    return isOnline
}